import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@styles/main.scss';

import { AuthStoreProvider } from '@myaccount/website-service';
/**
 * Components
 */
import Header from '@components/header/header';
import Footer from '@components/footer/footer';
import Modals from '@components/modals/modals';
import LoadExternalScript from '../components/utils/load-external-script'
const myaccountURL = process.env.GATSBY_MYACCOUNT_URL || '';

const Default = (props) => {
  const myaccountURL = process.env.GATSBY_MYACCOUNT_URL
  const myAccountScript = `${myaccountURL}/widgets/core/mascript.min.js`;
  const triggerMyAccount = () => {
    if (typeof window !== 'undefined') {
      if (typeof window.myAccount !== 'undefined') {
        window.myAccount({
          myAccountUrl: myaccountURL,
          apiUrl: process.env.GATSBY_STRAPI_SRC,
          cookieDomain: process.env.GATSBY_COOKIE_DOMAIN
        });
      }
    }
  }
  return (
    <AuthStoreProvider>
      <div className="wrapper">
        <script type="text/javascript" src="https://www.bugherd.com/sidebarv2.js?apikey=g8mx2rsgoxm3e7zksfenog" async={true}></script>
        <LoadExternalScript
          src={myAccountScript}
          // async="true"
          defer="true"
          loadScript={triggerMyAccount}
          // appendScriptTo="head"
        />
        <Header />
          {props.children}
        <Footer {...props} />
        <Modals />
      </div>
    </AuthStoreProvider>
  );
}

export default Default;

import React from "react";

import { addLinksToArray } from "@components/common/site/utils";
import { areaGuideUrl } from "@components/common/property/utils";
import {
    CONTACT_PAGE_URL,
    ABOUT_HOLROYDS_PAGE_URL,
    VALUATION_PAGE_URL,
    MEET_THE_TEAM_PAGE_URL,
    PROPERTY_SERVICES_PAGE_URL,
    TESTIMONIALS_PAGE_URL,
    LANDLORDS_PROPERTY_MANAGEMENT_PAGE_URL,
    LATEST_NEWS_PAGE_URL,
    PROPERTY_SERVICES_MORTGAGES_PAGE_URL
} from "@components/common/site/constants";
import { SEARCH_TYPES } from "@components/common/property/constants";

import { areasUrls, bedRoomsUrls } from "../../utils";
import PopularSearchAccordion from "../../accordion";

const PopularSearchDynamicPropertiesResultsSales = (props) => {

    let _props = {...props};

    // console.log('_props : ' + _props);
    // console.log( Object.keys(_props) );
    // console.log( Object.values(_props) );

    let _isDefaultSearchArea = _props.isDefaultSearchArea;
    let _areaFilter = _props.areaFilter;
    let _bedsFilter = _props.bedsFilter;
    let _priceFilter = _props.priceFilter;

    let _searchLocation = _props.searchLocation || '';
    let _building = _props.building || '';
    let _minBeds = _props.minBeds || '';

    // console.log('_isDefaultSearchArea : ' + _isDefaultSearchArea);
    // console.log('_areaFilter : ' + _areaFilter);
    // console.log('_bedsFilter : ' + _bedsFilter);
    // console.log('_priceFilter : ' + _priceFilter);

    // console.log('_searchLocation : ' + _searchLocation);
    // console.log('_building : ' + _building);
    // console.log('_minBeds : ' + _minBeds);

    let _areasSalesUrls = areasUrls({searchType: SEARCH_TYPES.SALES, beds: _minBeds});
    let _bedRoomsSalesUrls = bedRoomsUrls({searchType: SEARCH_TYPES.SALES, area: _searchLocation});

    let _areaGuideUrl = areaGuideUrl(_searchLocation);

    let col1Links = [];
    let col2Links = [];
    let col3Links = [];
    let col4Links = [];

    let _case = ' case-';

    if ( _isDefaultSearchArea && !_bedsFilter && !_priceFilter )  {
        _case += '1';

        addLinksToArray(_areasSalesUrls, col1Links);

        addLinksToArray(_bedRoomsSalesUrls, col2Links);

        col3Links.push(CONTACT_PAGE_URL);
        col3Links.push(ABOUT_HOLROYDS_PAGE_URL);
        col3Links.push(VALUATION_PAGE_URL);
        col3Links.push(_areaGuideUrl);
        col3Links.push(MEET_THE_TEAM_PAGE_URL);

        col4Links.push(PROPERTY_SERVICES_PAGE_URL);
        col4Links.push(TESTIMONIALS_PAGE_URL);
        col4Links.push(LANDLORDS_PROPERTY_MANAGEMENT_PAGE_URL);
        col4Links.push(LATEST_NEWS_PAGE_URL);
        col4Links.push(PROPERTY_SERVICES_MORTGAGES_PAGE_URL);
    } else if ( !_isDefaultSearchArea && !_bedsFilter && !_priceFilter )  {
        _case += '2';

        addLinksToArray(_bedRoomsSalesUrls, col1Links);

        addLinksToArray(_areasSalesUrls, col2Links);

        col3Links.push(VALUATION_PAGE_URL);
        col3Links.push(_areaGuideUrl);
        col3Links.push(CONTACT_PAGE_URL);
        col3Links.push(ABOUT_HOLROYDS_PAGE_URL);
        col3Links.push(MEET_THE_TEAM_PAGE_URL);

        col4Links.push(PROPERTY_SERVICES_PAGE_URL);
        col4Links.push(TESTIMONIALS_PAGE_URL);
        col4Links.push(LANDLORDS_PROPERTY_MANAGEMENT_PAGE_URL);
        col4Links.push(LATEST_NEWS_PAGE_URL);
        col4Links.push(PROPERTY_SERVICES_MORTGAGES_PAGE_URL);
    } else if ( !_isDefaultSearchArea && _areaFilter && _bedsFilter && _priceFilter )  {
        _case += '3';

        addLinksToArray(_areasSalesUrls, col1Links);

        addLinksToArray(_bedRoomsSalesUrls, col2Links);

        col3Links.push(VALUATION_PAGE_URL);
        col3Links.push(_areaGuideUrl);
        col3Links.push(CONTACT_PAGE_URL);
        col3Links.push(ABOUT_HOLROYDS_PAGE_URL);
        col3Links.push(MEET_THE_TEAM_PAGE_URL);

        col4Links.push(PROPERTY_SERVICES_PAGE_URL);
        col4Links.push(TESTIMONIALS_PAGE_URL);
        col4Links.push(LANDLORDS_PROPERTY_MANAGEMENT_PAGE_URL);
        col4Links.push(LATEST_NEWS_PAGE_URL);
        col4Links.push(PROPERTY_SERVICES_MORTGAGES_PAGE_URL);
    } else { // default
        _case += 'default';

        addLinksToArray(_areasSalesUrls, col1Links);

        addLinksToArray(_bedRoomsSalesUrls, col2Links);

        col3Links.push(CONTACT_PAGE_URL);
        col3Links.push(ABOUT_HOLROYDS_PAGE_URL);
        col3Links.push(VALUATION_PAGE_URL);
        col3Links.push(_areaGuideUrl);
        col3Links.push(MEET_THE_TEAM_PAGE_URL);

        col4Links.push(PROPERTY_SERVICES_PAGE_URL);
        col4Links.push(TESTIMONIALS_PAGE_URL);
        col4Links.push(LANDLORDS_PROPERTY_MANAGEMENT_PAGE_URL);
        col4Links.push(LATEST_NEWS_PAGE_URL);
        col4Links.push(PROPERTY_SERVICES_MORTGAGES_PAGE_URL);
    }

    _props.col1Links = col1Links;
    _props.col2Links = col2Links;
    _props.col3Links = col3Links;
    _props.col4Links = col4Links;

    return (
        <div class={`"component-PopularSearchDynamicPropertiesResultsSales${_case}"`}>
            <PopularSearchAccordion {..._props} />
        </div>
    )
}

export default PopularSearchDynamicPropertiesResultsSales;

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'gatsby';
import classNames from 'classnames'
import { StaticQuery, graphql } from "gatsby"
import CookieConsent from "react-cookie-consent";
// import { PopularSearch } from "../popular-search/index"


/**
 * Components
 */
import './assets/styles/_index.scss';
import FooterCol from '@components/footer/footer-col';
import PopularSearch from "@components/popular-search/index";

const year = new Date().getFullYear();

const Footer = (props,{className}) => (
  <StaticQuery
    query={graphql`
      query FooterQuery {

      strapiMenuFooter {
        Block_1_Title
        Block_2_Title
        Block_3_Title
        Block_2_Articles {
          Label
          URL
        }
        Block_1_Articles {
          Label
          URL
        }
        Block_3_Articles {
          Label
          URL
        }
      }

      strapiGlobalConfig {
        Footer_Bottom_Navigation {
          Menu {
            Label
            URL
          }
        }
      }

      }
    `}

    render={data => (
      <>
    <footer className={classNames('footer', className)}>
      <Container>

        {/* {console.log("Inside footer")} */}
        <PopularSearch {...props} />

        <Row>
          <FooterCol title={data.strapiMenuFooter.Block_1_Title}>
            <ul>
              {data.strapiMenuFooter.Block_1_Articles.map((Block_1_Articles, key) => {
                return <>
                  <li><Link to={`/${Block_1_Articles.URL}`}>{Block_1_Articles.Label}</Link></li>
                </>
              })}
            </ul>
          </FooterCol>
          <FooterCol title={data.strapiMenuFooter.Block_2_Title}>
            <ul>
              {data.strapiMenuFooter.Block_2_Articles.map((Block_2_Articles, key) => {
                return <>
                  <li><Link to={`/${Block_2_Articles.URL}`}>{Block_2_Articles.Label}</Link></li>
                </>
              })}
            </ul>
          </FooterCol>
          <FooterCol title={data.strapiMenuFooter.Block_3_Title}>
            <ul>
              {data.strapiMenuFooter.Block_3_Articles.map((Block_3_Articles, key) => {
                return <>
                  <li><Link to={`/${Block_3_Articles.URL}`}>{Block_3_Articles.Label}</Link></li>
                </>
              })}
            </ul>
          </FooterCol>
          <Col lg={3} md={6} className="contact-col">
            <h6>Get in touch</h6>
            <p><a href="tel:01535 610021">01535 610021</a></p>
            <div className="socials">
              <a href="https://en-gb.facebook.com/holroydsestateagents/" rel="noreferrer" target="_blank">
                F
              </a>
              <a href="https://twitter.com/holroydsuk?lang=en" rel="noreferrer" target="_blank">
                L
              </a>
              <a href="http://instagram.com" rel="noreferrer" target="_blank">
                I
              </a>
            </div>
          </Col>
        </Row>
        <Row className="copy footer-bottom-nav">
          <Col lg="6" md="12">
            <p>© {year} Starberry Estate Agents<br/>

            {data.strapiGlobalConfig.Footer_Bottom_Navigation.Menu.map((Menu, key) => {
            const foot_menu_length = data.strapiGlobalConfig.Footer_Bottom_Navigation.Menu.length
            if(foot_menu_length == key + 1)
            {
              var pipe = 'hide'
            }
            else
            {
              var pipe = 'show'
            }
              return <>
              <Link to={`/${Menu.URL}`}> {Menu.Label}</Link> {pipe == 'show' && <>|</> }
              </>
            })}

            </p>
          </Col>
          <Col lg="6" md="12" className="align-self-end text-lg-right site-by-logo">
            <p>Site by <a href="https://starberry.tv/" target="_blank"><img src="/svg/site-by-starberry.svg" /></a></p>
          </Col>
        </Row>
      </Container>



      <CookieConsent
          disableStyles={true}
          buttonClasses="btn btn-primary"
          containerClasses="cookie-popup d-lg-flex d-block"
          contentClasses=""
          buttonClasses="btn btn-link gotit"
          buttonText="Accept Cookies"

      >
          <p className="mb-0 text-center">We have placed cookies on your device to help make this website better. By continuing, you agree to our <Link to="/cookie-policy">Cookie Policy</Link>.</p>
      </CookieConsent>


    </footer>
      </>
    )}
  />
)

export default Footer;

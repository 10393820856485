import React, { useState,Component } from 'react';
import { Form, Button } from 'react-bootstrap';
import classNames from 'classnames';
import { navigate } from "@reach/router"
import $ from "jquery"

const salepriceminrange = [
  { value: "150000", label: "£150,000" },
  { value: "175000", label: "£175,000" },
  { value: "200000", label: "£200,000" },
  { value: "225000", label: "£225,000" },
  { value: "250000", label: "£250,000" },
  { value: "275000", label: "£275,000" },
  { value: "300000", label: "£300,000" },
  { value: "325000", label: "£325,000" },
  { value: "350000", label: "£350,000" },
  { value: "375000", label: "£375,000" },
  { value: "400000", label: "£400,000" },
  { value: "425000", label: "£425,000" },
  { value: "450000", label: "£450,000" },
  { value: "475000", label: "£475,000" },
  { value: "500000", label: "£500,000" },
  { value: "525000", label: "£525,000" },
  { value: "550000", label: "£550,000" },
  { value: "575000", label: "£575,000" },
  { value: "600000", label: "£600,000" },
  { value: "625000", label: "£625,000" },
  { value: "650000", label: "£650,000" },
  { value: "675000", label: "£675,000" },
  { value: "700000", label: "£700,000" },
  { value: "725000", label: "£725,000" },
  { value: "750000", label: "£750,000" },
  { value: "775000", label: "£775,000" },
  { value: "800000", label: "£800,000" },
  { value: "825000", label: "£825,000" },
  { value: "850000", label: "£850,000" },
  { value: "875000", label: "£875,000" },
  { value: "900000", label: "£900,000" },
  { value: "1000000", label: "£1,000,000" },
  { value: "1100000", label: "£1,100,000" },
  { value: "1200000", label: "£1,200,000" },
  { value: "1300000", label: "£1,300,000" },
  { value: "1400000", label: "£1,400,000" },
  { value: "1500000", label: "£1,500,000" },
  { value: "1600000", label: "£1,600,000" },
  { value: "1700000", label: "£1,700,000" },
  { value: "1800000", label: "£1,800,000" },
  { value: "1900000", label: "£1,900,000" },
  { value: "2000000", label: "£2,000,000" },
  { value: "2250000", label: "£2,250,000" },
  { value: "2500000", label: "£2,500,000" },
  { value: "2750000", label: "£2,750,000" },
  { value: "3000000", label: "£3,000,000" },
  { value: "3250000", label: "£3,250,000" },
  { value: "3500000", label: "£3,500,000" },
  { value: "3750000", label: "£3,750,000" },
  { value: "4000000", label: "£4,000,000" },
  { value: "4250000", label: "£4,250,000" },
  { value: "4500000", label: "£4,500,000" },
  { value: "4750000", label: "£4,750,000" },
  { value: "5000000", label: "£5,000,000" }
]

const salepricemaxrange = [
  { value: "150000", label: "£150,000" },
  { value: "175000", label: "£175,000" },
  { value: "200000", label: "£200,000" },
  { value: "225000", label: "£225,000" },
  { value: "250000", label: "£250,000" },
  { value: "275000", label: "£275,000" },
  { value: "300000", label: "£300,000" },
  { value: "325000", label: "£325,000" },
  { value: "350000", label: "£350,000" },
  { value: "375000", label: "£375,000" },
  { value: "400000", label: "£400,000" },
  { value: "425000", label: "£425,000" },
  { value: "450000", label: "£450,000" },
  { value: "475000", label: "£475,000" },
  { value: "500000", label: "£500,000" },
  { value: "525000", label: "£525,000" },
  { value: "550000", label: "£550,000" },
  { value: "575000", label: "£575,000" },
  { value: "600000", label: "£600,000" },
  { value: "625000", label: "£625,000" },
  { value: "650000", label: "£650,000" },
  { value: "675000", label: "£675,000" },
  { value: "700000", label: "£700,000" },
  { value: "725000", label: "£725,000" },
  { value: "750000", label: "£750,000" },
  { value: "775000", label: "£775,000" },
  { value: "800000", label: "£800,000" },
  { value: "825000", label: "£825,000" },
  { value: "850000", label: "£850,000" },
  { value: "875000", label: "£875,000" },
  { value: "900000", label: "£900,000" },
  { value: "1000000", label: "£1,000,000" },
  { value: "1100000", label: "£1,100,000" },
  { value: "1200000", label: "£1,200,000" },
  { value: "1300000", label: "£1,300,000" },
  { value: "1400000", label: "£1,400,000" },
  { value: "1500000", label: "£1,500,000" },
  { value: "1600000", label: "£1,600,000" },
  { value: "1700000", label: "£1,700,000" },
  { value: "1800000", label: "£1,800,000" },
  { value: "1900000", label: "£1,900,000" },
  { value: "2000000", label: "£2,000,000" },
  { value: "2250000", label: "£2,250,000" },
  { value: "2500000", label: "£2,500,000" },
  { value: "2750000", label: "£2,750,000" },
  { value: "3000000", label: "£3,000,000" },
  { value: "3250000", label: "£3,250,000" },
  { value: "3500000", label: "£3,500,000" },
  { value: "3750000", label: "£3,750,000" },
  { value: "4000000", label: "£4,000,000" },
  { value: "4250000", label: "£4,250,000" },
  { value: "4500000", label: "£4,500,000" },
  { value: "4750000", label: "£4,750,000" },
  { value: "5000000", label: "£5,000,000" }
]

const rentpriceminrange = [
  { value: "100", label: "£100 pcm" },
  { value: "200", label: "£200 pcm" },
  { value: "300", label: "£300 pcm" },
  { value: "400", label: "£400 pcm" },
  { value: "500", label: "£500 pcm" },
  { value: "750", label: "£750 pcm" },
  { value: "1000", label: "£1,000 pcm" },
  { value: "2000", label: "£2,000 pcm" },
  { value: "3000", label: "£3,000 pcm" },
  { value: "4000", label: "£4,000 pcm" },
  { value: "5000", label: "£5,000 pcm" },
  { value: "10000", label: "£10,000 pcm" }
]

const rentpricemaxrange = [
  { value: "100", label: "£100 pcm" },
  { value: "200", label: "£200 pcm" },
  { value: "300", label: "£300 pcm" },
  { value: "400", label: "£400 pcm" },
  { value: "500", label: "£500 pcm" },
  { value: "750", label: "£750 pcm" },
  { value: "1000", label: "£1,000 pcm" },
  { value: "2000", label: "£2,000 pcm" },
  { value: "3000", label: "£3,000 pcm" },
  { value: "4000", label: "£4,000 pcm" },
  { value: "5000", label: "£5,000 pcm" },
  { value: "10000", label: "£10,000 pcm" }
]

export class PropertyForm extends Component {
  constructor() {
      super();
      this.state = {
          salemin:"",
          salemax:"",
          location:"",
          showbedroom: true,
          showsaleprice: true,
          showrentprice: false,
          showrentmaxprice: true,
          showsalemaxprice: true,
          popminSalesPriceRange_s: salepriceminrange,
          popmaxSalesPriceRange_s: salepricemaxrange,
          popminRentalsPriceRange_s: rentpriceminrange,
          popmaxRentalsPriceRange_s: rentpricemaxrange
      }
  }
  handleChange = (event) => {
    if ("query" === event.currentTarget.name) {
      this.setState({
        location : event.currentTarget.value
      })
  }
    console.log(event.currentTarget.value)
    if ( "sales" === event.currentTarget.value )
    {
      this.setState({showbedroom: true, showsaleprice: true, showrentprice: false, showbuildtype: false});
    }
    if ( "lettings" === event.currentTarget.value )
    {
      this.setState({showbedroom: true, showsaleprice: false, showrentprice: true, showbuildtype: false});
    }
  }
   handleSelectPriceChange = (event) => {
    if ( "price_min_sales" == event.currentTarget.name ) {
      if (event.currentTarget.value !== undefined && event.currentTarget.value !== "") {
        this.setState({ popmaxSalesPriceRange_s : salepricemaxrange.filter((x) => parseInt(x.value) > parseInt(event.currentTarget.value) ) })
        if ( "1000000"  == event.currentTarget.value ) {
          this.setState({ showsalemaxprice : false })
        } else {
          this.setState({ showsalemaxprice : true })
        }
      }
      else {
        this.setState({ popmaxSalesPriceRange_s : salepricemaxrange })
        this.setState({ showsalemaxprice : true })
      }
    }
    if ( "price_max_sales" == event.currentTarget.name ) {
      if (event.currentTarget.value !== undefined && event.currentTarget.value !== "") {
        this.setState({ popminSalesPriceRange_s : salepriceminrange.filter((x) => parseInt(x.value) < parseInt(event.currentTarget.value) ) })
      }
      else
        this.setState({ popminSalesPriceRange_s : salepriceminrange })
    }
    if ( "price_min_rentals" == event.currentTarget.name ) {
      if (event.currentTarget.value !== undefined && event.currentTarget.value !== "") {
        this.setState({ popmaxRentalsPriceRange_s : rentpricemaxrange.filter((x) => parseInt(x.value) > parseInt(event.currentTarget.value) ) })
        if ( "2000"  == event.currentTarget.value ) {
          this.setState({ showrentmaxprice : false })
        } else {
          this.setState({ showrentmaxprice : true })
        }
      }
      else {
        this.setState({ popmaxRentalsPriceRange_s : rentpricemaxrange })
        this.setState({ showrentmaxprice : true })
      }
    }
    if ( "price_max_rentals" == event.currentTarget.name ) {
      if (event.currentTarget.value !== undefined && event.currentTarget.value !== "") {
        this.setState({ popminRentalsPriceRange_s : rentpriceminrange.filter((x) => parseInt(x.value) < parseInt(event.currentTarget.value) ) })
      }
      else
        this.setState({ popminRentalsPriceRange_s : rentpriceminrange })
    }
  }

  // TODO: hard coded details to be removed/replaced with common variables
handleSubmit = (event) => {
    event.preventDefault();
    const formsdata = (event.target);
    const json = {}
    Object.keys(formsdata).map((key) => {
      json[formsdata[key].name] = formsdata[key].value
    })
    console.log(json)
    //json['property_type'] = "lettings";

    let url = "/";
    if (json['query'] !== "") {
      url = url+"in-"+json['query']+"/"
    }
    else {
      url = url+"in-west-yorkshire/"
    }
    if (json['property_type'] === "sales" || json['property_type'] === "lettings") {
      if (json['bedroom'] !== "") {
        url = url+json['bedroom']+"-and-more-bedrooms/"
      }
      if (json['property_type'] === "sales") {
        if (json['price_min_sales'] !== "" && json['price_max_sales'] !== "") {
          url = url+"between-"+json['price_min_sales']+"-and-"+json['price_max_sales']
        }
        else if (json['price_max_sales'] !== "") {
          url = url+"under-"+json['price_max_sales']
        }
        else if (json['price_min_sales'] !== "") {
          url = url+"over-"+json['price_min_sales']
        }
      }
      if (json['property_type'] === "lettings") {
        if (json['price_min_rentals'] !== "" && json['price_max_rentals'] !== "") {
          url = url+"between-"+json['price_min_rentals']+"-and-"+json['price_max_rentals']
        }
        else if (json['price_max_rentals'] !== "") {
          url = url+"under-"+json['price_max_rentals']
        }
        else if (json['price_min_rentals'] !== "") {
          url = url+"over-"+json['price_min_rentals']
        }
      }
    }

    if ( "/" === url.substr(url.length -1) )
      url = url.substr(0, url.length -1)

    if (json['property_type'] === "sales") {
      navigate("/property/for-sale"+url);
      setTimeout(() => {
         $( ".custom-modal__close" ).trigger( "click" )
       },100);

   } else if (json['property_type'] === "lettings") {
      navigate("/property/to-rent"+url);
      setTimeout(() => {
         $( ".custom-modal__close" ).trigger( "click" )
       },100);

    }
  }
 render() {
  return (
    <>
    <div className={classNames('property-form')}>
      <h3 className="text-center">Property Search</h3>
      <Form onSubmit={this.handleSubmit}>
        <Form.Group>
          <div className="custom-float custom-float--select" >
            <Form.Label>Search Type</Form.Label>
                       <Form.Control as="select"  name="property_type" onChange={this.handleChange}>
                          <option value="sales" key="0" selected="selected">Sales</option>
                          <option value="lettings" key="1">Lettings</option>
                       </Form.Control>
          </div>
          <Form.Text className="form-helper">
            Helper Text
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <div className="custom-float">
            <Form.Label>Location</Form.Label>
            <Form.Control
              id="query"
              name="query"
              value={this.state.location}
              onChange={this.handleChange}
            />
          </div>
          <Form.Text className="form-helper">
            Helper Text
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <div className={!this.state.showsaleprice ? 'd-none' : 'custom-float custom-float--select'} >
            <Form.Label>Minimum Price</Form.Label>
<Form.Control as="select"  name="price_min_sales" onChange={this.handleSelectPriceChange}>
                          <option value="" key="0" aria-label="empty"></option>
                            {this.state.popminSalesPriceRange_s.map((option, key) => {
                                return(
                                  <option value={option.value} key={key}>
                                    {option.label}
                                  </option>
                                )
                              })
                            }
                       </Form.Control>
          </div>
          <Form.Text className="form-helper">
            Helper Text
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <div className={!this.state.showsaleprice ? 'd-none' : 'custom-float custom-float--select'}>
            <Form.Label>Maximum Price</Form.Label>
            <Form.Control as="select" name="price_max_sales" disabled={this.state.showsalemaxprice ? '' : 'disabled'}  onChange={this.handleSelectPriceChange}>
                          <option value="" key="0" aria-label="empty"></option>
                            {
                              (this.state.popmaxSalesPriceRange_s).map((option, key) => {
                                return(
                                  <option value={option.value} key={key}>
                                    {option.label}
                                  </option>
                                )
                              })
                            }
                       </Form.Control>
          </div>
          <Form.Text className="form-helper">
            Helper Text
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <div className={!this.state.showrentprice ? 'd-none' : 'custom-float custom-float--select'}>
            <Form.Label>Minimum Price</Form.Label>
<Form.Control as="select" name="price_min_rentals" onChange={this.handleSelectPriceChange}>
                          <option value="" key="0"></option>
                            {
                              (this.state.popminRentalsPriceRange_s).map((option, key) => {
                                return(
                                  <option value={option.value} key={key}>
                                    {option.label}
                                  </option>
                                )
                              })
                            }
                       </Form.Control>
</div>
          <Form.Text className="form-helper">
            Helper Text
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <div className={!this.state.showrentprice ? 'd-none' : 'custom-float custom-float--select'}>
            <Form.Label>Maximum Price</Form.Label>
            <Form.Control as="select" name="price_max_rentals" disabled={this.state.showrentmaxprice ? '' : 'disabled'} onChange={this.handleSelectPriceChange}>
                          <option value="" key="0"></option>
                            {
                              (this.state.popmaxRentalsPriceRange_s).map((option, key) => {
                                return(
                                  <option value={option.value} key={key}>
                                    {option.label}
                                  </option>
                                )
                              })
                            }
                       </Form.Control>
</div>
          <Form.Text className="form-helper">
            Helper Text
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <div className="custom-float custom-float--select">
            <Form.Label>Bedrooms</Form.Label>
            <Form.Control
              as="select"
              name="bedroom"
              id="bedroom"
            >
              <option value="" aria-label="empty"></option>
              <option value="1">1+ Bedrooms</option>
              <option value="2">2+ Bedrooms</option>
              <option value="3">3+ Bedrooms</option>
              <option value="4">4+ Bedrooms</option>
              <option value="5">5+ Bedrooms</option>
              <option value="6">6+ Bedrooms</option>
            </Form.Control>
          </div>
          <Form.Text className="form-helper">
            Helper Text
          </Form.Text>
        </Form.Group>
        <Button variant="primary" type="submit">
          Search
        </Button>
      </Form>
    </div>
    </>
  )
}
}

export default PropertyForm;
